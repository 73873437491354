/* Ensure that rows behave like flex containers */
.row {
    display: flex;
    flex-wrap: wrap;
  }
  
  /* Ensure that columns (panels) stretch to equal height */
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .card-body {
    background-color: #FFF;
  }

  .section-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 60px 0;
  }
  
  .section-divider h2 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin: 0 15px;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .section-divider h2:hover {
    color: #1f5075; /* Subtle color shift on hover */
    transform: translateY(-3px); /* Gentle lift effect */
  }
  
  .section-divider i {
    font-size: 1.5rem;
    color: #1f5075;
    transition: transform 0.3s ease;
  }
  
  .section-divider i:hover {
    transform: scale(1.1); /* Slight scale-up on hover */
  }
  
  .section-divider::before,
  .section-divider::after {
    content: "";
    flex-grow: 1;
    height: 2px;
    background: linear-gradient(to right, #276ca7, #1f5075); /* Gradient line */
    margin: 0 15px;
    transition: background 0.3s ease;
  }
  
  .section-divider:hover::before,
  .section-divider:hover::after {
    background: linear-gradient(to right, #1f5075, #276ca7); /* Subtle gradient shift on hover */
  }
  
  .section-divider .icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: linear-gradient(to right, #276ca7, #1f5075);
    border-radius: 50%;
    margin-right: 15px; /* Space between the icon and text */
    transition: transform 0.3s ease;
  }
  
  .section-divider .icon-wrap i {
    color: white;
    font-size: 20px; /* Adjusted size for better centering */
  }
  
  .section-divider .icon-wrap:hover {
    transform: scale(1.05); /* Slight scaling effect on hover */
  }

  .peril-details {
    margin-top: 15px;
  }
  
  .peril-details p {
    margin-bottom: 0.75rem;
  }
  
/* Modern card styling */
.modern-card {
  background-color: #f7f8fa;
  padding: 5px;
  border-radius: 12px;
  position: relative;
  transition: all 0.2s ease;
  min-height: 150px;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05); /* Soft shadow for modern look */
}

.modern-card:hover {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.modern-peril-panel {
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08); /* Slight elevation */
  transition: all 0.3s ease;
  padding: 5px;
}

.modern-peril-panel:hover {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
  /* transform: translateY(-3px); */
}

.icon-shape {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modern-icon {
  font-size: 0.9rem;
  width: 40px;
  height: 40px;
}

.card-header-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.card-title {
  font-size: 1rem;
  font-weight: bold;
  color: #343a40;
}

.modern-text {
  margin-top: 10px;
  color: #495057;
  font-size: 0.85rem;
  line-height: 1.6;
}

.modern-summary {
  background-color: #f0f4f8;
  padding: 15px;
  border-radius: 8px;
  font-size: 0.95rem;
}

.list-unstyled {
  padding-left: 0;
  margin-bottom: 0;
}

.card-header .text-muted {
  font-size: 0.75rem;
  font-weight: 600;
}

button {
  border-radius: 20px;
  font-weight: 500;
  padding: 6px 12px;
}

button:hover {
  background-color: #0069d9;
  color: white;
}

/* Equal sizing of rows */
.col-md-6 {
  max-width: 50%;
}

/* Modern list with styled bullet points */
.modern-card .modern-list-item {
  list-style-type: disc; /* Standard bullet point */
  padding-left: 2px;    /* Indentation for the bullet point */
  margin-bottom: 10px;   /* Space between list items */
  font-weight: 500;      /* Make the text slightly bold */
  color: #495057;        /* Text color */
}

.modern-card .modern-list-item::marker {
  color: #1f5075; /* Custom color for the bullet points */
  font-size: 0.5rem; /* Increase bullet point size slightly */
}

/* Ensure no bullets are visible by default in unordered list */
ul.list-unstyled {
  list-style: none; /* Removes the default bullets */
  padding-left: 0;  /* No default padding */
}

ul.list-unstyled li {
  margin-bottom: 10px;
}

/* For custom bullet points in modern list */
ul.modern-list {
  padding-left: 20px;  /* Adds padding for bullet points */
}

ul.modern-list li {
  list-style-type: disc; /* Adds disc bullet points */
  color: #343a40; /* Text color for modern list items */
  margin-bottom: 10px;
}

.date-button, .amount-button {
  font-size: 0.9rem;
  padding: 8px 12px;
  border-radius: 20px;
}

.date-button {
  border-color: #17a2b8;
  color: #17a2b8;
}

.amount-button {
  border-color: #28a745;
  color: #28a745;
}
