/* Icon size and circle adjustment */
.icon-container {
  width: 50px; /* Increased circle size */
  height: 50px; /* Increased circle size */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.icon-container i {
  font-size: 1.5rem; /* Adjusted icon size */
}

/* Force the number to stick to the top */
.stat-number {
  font-size: 2rem; /* Adjust the number font size */
  font-weight: bold;
}

/* Align the percentage and arrow at the bottom */
.stat-content {
  margin-top: auto;
  margin-bottom: 0;
  text-align: left;
}

.card-stats .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.widget-numbers {
  margin-bottom: 0;
  padding-bottom: 0;
}
