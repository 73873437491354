/* Existing Styles */
.file-tree {
  margin-left: 0;
}

.file-tree .list-group-item {
  border: none;
  padding-left: 1.5rem;
}

.file-tree .list-group-item .bi {
  font-size: 1.2rem;
}

.folder-node {
  font-weight: bold;
  cursor: pointer; /* Moved cursor style here */
}

.file-node {
  font-weight: normal;
  cursor: default; /* Moved cursor style here */
}

.file-tree .collapse .list-group-item {
  padding-left: 2.5rem;
}

.file-tree .list-group-item:hover {
  background-color: #f8f9fa;
}

/* New Styles */

/* Dropzone Styles */
.dropzone {
  border: 2px dashed #1f5075;
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease;
}

.dropzone-active {
  background-color: #f0f8ff;
}

.dropzone-icon {
  font-size: 5rem;
  color: rgba(0, 123, 255, 0.3);
  transition: color 0.2s ease;
}

.dropzone.dropzone-active .dropzone-icon {
  color: rgba(0, 123, 255, 0.6);
}

.dropzone p {
  font-size: 1.2rem;
  color: #6c757d;
}

/* Loading Overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

/* Spinner Styles */
.large-spinner {
  width: 3rem;
  height: 3rem;
}

/* File Icon Styles */
.file-icon {
  font-size: 1.2rem;
  margin-right: 8px;
}

/* FileTree CardBody Styles */
.filetree-body {
  max-height: 400px;
  overflow-y: auto;
}