.custom-modal {
    max-width: 80% !important;
    width: 80%;
  }
  
  .nav-column {
    padding-right: 15px;
    border-right: 1px solid #ddd;
    min-width: 200px; /* Optional */
  }
  
  .nav-item-spacing {
    margin-bottom: 10px;
  }
  
  .nav-button {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 15px;
    background-color: #f8f9fa;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .nav-button:hover {
    background-color: #e2e6ea;
  }
  
  .nav-button.active {
    background-color: #1f5075;
    color: #fff;
  }
  
  .nav-button-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .document-name {
    flex: 1;
    text-align: left;
    margin-right: 10px;
  }
  
  .document-type-icon {
    flex-shrink: 0;
    color: #6c757d;
  }
  
  .active-icon {
    color: #fff;
  }
  
  .content-column {
    padding-left: 15px;
    overflow-y: auto;
    max-height: 70vh;
  }
  
  .custom-modal .modal-body {
    padding: 20px;
  }
  
  .custom-modal .modal-footer {
    border-top: 1px solid #ddd;
  }
  
  .pdf-absolute {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
  }

  .pdf-highlighter-wrapper {
    position: relative;
    height: 500px;
    width: 100%;
    border: 1px solid #ccc;
    overflow: hidden;
  }
  
  .pdf-highlighter-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    padding: 5px;
  }
  